import './style.css'
import * as THREE from 'three'
import * as dat from 'lil-gui'
import gsap, { random } from 'gsap'
import CANNON from 'cannon'
/**
 * Debug
 */
// const gui = new dat.GUI()

const parameters = {
    materialColor: '#ffeded'
}


// gui
//     .addColor(parameters, 'materialColor')
//     .onChange(()=>{
//         material.color.set(parameters.materialColor)
//         particlesMaterial.color.set(parameters.materialColor)
//     })
    const debugObject = {}

    debugObject.createSphere = () => {
        
        createSphere(
            0.1 + Math.random() * 0.5, 
            {
                x : (Math.random() - 0.5) * 15 , 
                y: 10, 
                z : -35 - Math.random() *5 }
            )
        
        
    }

    // z : (Math.random() - 0.5) * 3
    debugObject.createBox = () => {
        createBox(
            Math.random(), 
            Math.random(), 
            Math.random(), 
            {
                x : (Math.random() - 0.5) * 15 , 
                y: 10, 
                z : -35 - Math.random() *5 }
        )
    }
    
    debugObject.reset = () => {
        for(const object of objectsToUpdate)
        {
            world.remove(object.body)
            scene.remove(object.mesh)
        }
        objectsToUpdate.splice(0, objectsToUpdate.length)
    }
//     gui.add(debugObject, 'createSphere')
//     gui.add(debugObject, 'createBox')
//     gui.add(debugObject, 'reset')
/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// TEXTURE

const textureLoader= new THREE.TextureLoader()
const gradientTexture = textureLoader.load('textures/gradients/3.jpg')
gradientTexture.magFilter = THREE.NearestFilter

const cubeTextureLoader = new THREE.CubeTextureLoader()

const environmentMapTexture = cubeTextureLoader.load([
    '/textures/environmentMaps/2/px.png',
    '/textures/environmentMaps/2/nx.png',
    '/textures/environmentMaps/2/py.png',
    '/textures/environmentMaps/2/ny.png',
    '/textures/environmentMaps/2/pz.png',
    '/textures/environmentMaps/2/nz.png'
])

// PHYSICCS -----------------------------------------------------------------
const world = new CANNON.World()
world.broadphase = new CANNON.SAPBroadphase(world)
world.allowSleep = true
world.gravity.set(0, - 9.82, 0)
const defaultMaterial = new CANNON.Material('default')

const defaultContactMaterial = new CANNON.ContactMaterial(
    defaultMaterial,
    defaultMaterial,
    {
        friction: 0.1,
        restitution: 0.7,
    }
)
world.addContactMaterial(defaultContactMaterial)
const floorShape = new CANNON.Plane()
const floorBody = new CANNON.Body()
floorBody.material = defaultMaterial
floorBody.mass =0 
floorBody.addShape(floorShape)
floorBody.quaternion.setFromAxisAngle(
    new CANNON.Vec3(-1,0,0),
    Math.PI / 4,
)
floorBody.position.z = -30


world.addBody(floorBody)

const floor = new THREE.Mesh(
new THREE.PlaneGeometry(25, 100),
new THREE.MeshStandardMaterial({
    color: '#777777',
    metalness: 0.9,
    roughness: 0.4,
    envMap: environmentMapTexture,
    envMapIntensity: 0.5
})
)

floor.rotation.x = - Math.PI * 0.25


floor.position.set(0,0, -30)

scene.add(floor)




// UTILS ------------
const objectsToUpdate = []

const SphereGeometry = new THREE.SphereGeometry(1, 20, 20)
const sphereMaterial = new THREE.MeshStandardMaterial({
    metalness: 0.5, 
    roughness: 0.5, 
    envMap: environmentMapTexture
})
const createSphere = (radius, position) =>
{
    // THREEJS 
    const mesh = new THREE.Mesh(
        SphereGeometry,
        sphereMaterial
    )
    mesh.scale.set(radius,radius,radius)
    // mesh.castShadow = true
    mesh.position.copy(position)
    scene.add(mesh)

    // CANNONJS
    const shape = new CANNON.Sphere(radius)
    const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(0,3,0),
        shape: shape,
        material: defaultMaterial,
    })
    body.position.copy(position)
    world.addBody(body)

    // UPDATE
    objectsToUpdate.push({
        mesh: mesh,
        body: body
    })
}


const BoxGeometry = new THREE.BoxGeometry(1, 1, 1)
const BoxMaterial = new THREE.MeshStandardMaterial({
    metalness: 0.9, 
    roughness: 0.8, 
    envMap: environmentMapTexture
})

const createBox = (width, height, depth, position) =>
{
    // THREEJS 
    const mesh = new THREE.Mesh(
        BoxGeometry,
        // BoxMaterial
        material
    )
    mesh.scale.set(width,height,depth)
    // mesh.castShadow = true
    mesh.position.copy(position)
    scene.add(mesh)

    // CANNONJS
    const shape = new CANNON.Box(new CANNON.Vec3(width/2, height/2, depth/2))
    const body = new CANNON.Body({
        mass: 1,
        position: new CANNON.Vec3(0,3,0),
        shape: shape,
        material: defaultMaterial,
    })
    body.position.copy(position)
    world.addBody(body)

    // UPDATE
    objectsToUpdate.push({
        mesh: mesh,
        body: body
    })
}


/**
 * Objects
 */
const objectsDistance = 4
const material = new THREE.MeshToonMaterial({
    color: parameters.materialColor,
    gradientMap: gradientTexture,
})
const mesh1 = new THREE.Mesh(
    new THREE.TorusGeometry(1,0.4,16,60),
    material
)

const mesh2 = new THREE.Mesh(
    new THREE.ConeGeometry(1,2,32),
    material
)

const mesh3 = new THREE.Mesh(
    new THREE.TorusKnotGeometry(0.8,0.35,100,16),
    material
)
mesh1.position.y = - objectsDistance * 0
mesh2.position.y = - objectsDistance * 1
mesh3.position.y = - objectsDistance * 2

mesh1.position.x = 2
mesh2.position.x = -2
mesh3.position.x = 2

scene.add(mesh1,mesh2,mesh3)

const sectionMeshes = [mesh1,mesh2,mesh3]

// Particles
const particlesCount =200
const positions = new Float32Array(particlesCount * 3)
for( let i = 0; i < particlesCount; i++){
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10
    positions[i * 3 + 1] = objectsDistance * 0.4 - Math.random() * objectsDistance * sectionMeshes.length
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10
}

const particlesGeometry = new THREE.BufferGeometry()
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 0.03
})

const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)
//
// LIGHT

const directionalLight= new THREE.DirectionalLight('#ffffff',1)
directionalLight.position.set(1,1,0)

scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

window.addEventListener('load', () => {
    debugObject.createBox();
    debugObject.createBox();
    debugObject.createBox();
    debugObject.createSphere();
})
/**
 * Camera
 */
const cameraGroup = new THREE.Group()
scene.add(cameraGroup)
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 6
cameraGroup.add(camera)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true,
})

renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

//SCROLL
let scrollY = window.scrollY

let currentSection = 0
window.addEventListener('scroll', ()=>{
    scrollY = window.scrollY

    const newSection = Math.round(scrollY / sizes.height)

    if(newSection != currentSection)
    {
        currentSection = newSection

        gsap.to(
            sectionMeshes[currentSection].rotation, 
            {
                duration: 1.5,
                ease: 'power2.inOut',
                x: '+=6',
                y: '+=3',
                z: '+=0.5'
            }
        )
    
    }
})

//  CURSOR
const cursor = {}
cursor.x = 0
cursor.y = 0

window.addEventListener('mousemove', (event) =>{
    cursor.x = event.clientX / sizes.width - 0.5
    cursor.y = event.clientY / sizes.height - 0.5

})

window.addEventListener('keypress', (event)=>{
    if(event.key == "q"){
        debugObject.createSphere()
    }
    if(event.key == "w"){
        debugObject.createBox()
    }
})

window.addEventListener('mousedown', ()=>{
    if(Math.random()> 0.5){
        createBox(Math.random(),Math.random(),Math.random(),{
            x : cursor.x * 50, 
            y: 10, 
            z : -35 - Math.random() *5 } )
    }else{
        createSphere(Math.random(), 
        {x : cursor.x * 50, 
            y: 10, 
            z : -35 - Math.random() *5 } )
    }
})


/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime   

    world.step( 1/60, deltaTime ,3)
    for( const object of objectsToUpdate)
    {
        object.mesh.position.copy(object.body.position)
        object.mesh.quaternion.copy(object.body.quaternion)
    }
    //Animate camera
    camera.position.y = - scrollY / sizes.height * objectsDistance

    const parallaxX = cursor.x * 0.5 
    const parallaxY = - cursor.y * 0.5
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 4 * deltaTime
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 4 * deltaTime
    // Animate meshes
    for(const mesh of sectionMeshes)
    {
        mesh.rotation.x += deltaTime * 0.1
        mesh.rotation.y += deltaTime * 0.12
    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()